import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { GET_ALL_PROTOCOL_OUTLINES, SUBMIT_EXPORT } from './graphql';
import { GET_TOASTS } from '../global/graphql';
import {
  Autocomplete,
  createFilterOptions,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormLabel,
  Radio,
  Alert,
  AlertTitle,
} from '@mui/material';

import { ALL_BUILDINGS } from '../utils';
import { SMPHAnimals } from '../components/SpeciesSearch';

import PhoneInput from './PhoneInput';
import EmailList from './EmailList';
import FormDivider from '../components/FormDivider';
import SubmitterGroup from './SubmitterGroup';
import TableSwitch, { isUSDAAnimal } from './TableSwitch';
import ContainerTable from './ContainerTable';
import SelectInput from './SelectInput';
import { buildingNameToCode, sleep } from '../utils';

import StandardWrapper from '../components/StandardWrapper';

const ExportForm = ({ user }) => {
  const [toast, setToast] = useState();
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateWeekendDialog, setDateWeekendDialog] = useState(false);
  const [dateCheckDialog, setDateCheckDialog] = useState(false);
  const [formReset, setFormReset] = useState(false);
  const [sendingReset, setSendingReset] = useState(false);
  const [receivingReset, setReceivingReset] = useState(false);
  const [userInfo] = useState(user);

  const [vars, setVars] = useState({
    immunodeficient: false,
    submitterEmail: userInfo.emailAddress,
    sendingProtocolNumber: '',
    sendingBuilding: '',
    sendingRoom: '',
    sendingContactName: '',
    sendingContactEmail: '',
    sendingContactPhone: '',
    species: '',
    naive: false,
  });
  const [namedContacts, setNamedContacts] = useState([
    {
      name: userInfo.firstName + ' ' + userInfo.lastName,
      email: userInfo.emailAddress,
      origin: 'submitter',
    },
  ]);
  const [protocol, setProtocol] = useState();
  const [sendingSave, setSendingSave] = useState();
  const [receivingSave, setReceivingSave] = useState();
  const [validationDialogMessage, setValidationDialogMessage] = useState();
  const [USDA, setUSDA] = useState(isUSDAAnimal(vars.species ? vars.species : '').isUSDA);
  const [infoToasts, setInfoToasts] = useState([]);
  const [breedingCore, setBreedingCore] = useState(false);

  // Info toast logic
  const { data: toastData, loading: toastsLoading } = useQuery(GET_TOASTS, {
    variables: { form: 'export' },
  });

  useEffect(() => {
    if (!toastsLoading && Array.isArray(toastData?.toasts)) {
      setInfoToasts(toastData.toasts.map((x) => ({ ...x, active: true })));
    }
  }, [toastsLoading, toastData?.toasts]);

  const disableInfoToast = (_id) => {
    setInfoToasts(
      infoToasts.map((x) => {
        if (_id === x._id) {
          return { ...x, active: false };
        }
        return { ...x };
      })
    );
  };

  // End info toast logic

  const validate = () => {
    if (USDA.isUSDA) {
      if (!vars.animals || !Array.isArray(JSON.parse(vars.animals)) || JSON.parse(vars.animals).length < 1) {
        setValidationDialogMessage('You must add an animal in the table');
        return false;
      }
    } else if (
      !vars.containers ||
      !Array.isArray(JSON.parse(vars.containers)) ||
      JSON.parse(vars.containers).length < 1
    ) {
      setValidationDialogMessage('You must add a container in the table');
      return false;
    }

    if (!vars.mtaStatus) {
      setValidationDialogMessage('Please select an MTA status');
      return false;
    }

    if (!vars.bsl) {
      setValidationDialogMessage('Please select a biosafety level status');
      return false;
    }
    return true;
  };

  const resetForm = () => {
    const _vars = {};
    Object.keys(vars).forEach((key) => {
      _vars[key] = '';
    });

    if (!sendingSave) {
      sendingReset ? setSendingReset(false) : setSendingReset(true);
    } else {
      _vars.sendingProtocolNumber = vars.sendingProtocolNumber;
      _vars.sendingBuilding = vars.sendingBuilding;
      _vars.sendingRoom = vars.sendingRoom;
      _vars.sendingContactName = vars.sendingContactName;
      _vars.sendingContactEmail = vars.sendingContactEmail;
      _vars.sendingContactPhone = vars.sendingContactPhone;
      _vars.sendingBillingString = vars.sendingBillingString;
      // _vars.dateRequired = vars.dateRequired;
    }
    if (!receivingSave) {
      receivingReset ? setReceivingReset(false) : setReceivingReset(true);
    } else {
      _vars.receivingContactName = vars.receivingContactName;
      _vars.receivingContactEmail = vars.receivingContactEmail;
      _vars.receivingContactPhone = vars.receivingContactPhone;
      _vars.receivingInstitution = vars.receivingInstitution;
      _vars.receivingPI = vars.receivingPI;
      _vars.receivingTransferCoordinatorName = vars.receivingTransferCoordinatorName;
      _vars.receivingTransferCoordinatorEmail = vars.receivingTransferCoordinatorEmail;
      _vars.receivingTransferCoordinatorPhone = vars.receivingTransferCoordinatorPhone;
    }

    formReset ? setFormReset(false) : setFormReset(true);

    setVars({
      ..._vars,
      immunodeficient: false,
      submitterEmail: userInfo.emailAddress,
      submitterPhone: '',
      // dateRequired: vars.dateRequired,
      naive: false,
    });
  };

  const CREATE_ERROR = gql`
    mutation CreateError($PVI: String, $action: String, $error: String, $data: String) {
      createError(PVI: $PVI, action: $action, error: $error, data: $data)
    }
  `;
  const [createError] = useMutation(CREATE_ERROR);

  const [addExport] = useMutation(SUBMIT_EXPORT, {
    onError(err) {
      setLoading(false);
      console.log(err);
      setErrorDialog(true);
      setToast(false);
      createError({
        variables: {
          PVI: user.PVI,
          action: 'submitExport',
          error: err ? JSON.stringify(err) : undefined,
          data: JSON.stringify({
            user,
            vars,
          }),
        },
      });
    },
    onCompleted() {
      if (!errorDialog) {
        setLoading(false);
        setToast(true);
        resetForm();
      } else setToast(false);
    },
  });

  const { data: protocolData } = useQuery(GET_ALL_PROTOCOL_OUTLINES);
  const protocols = protocolData?.allProtocolOutlines;

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setDateCheckDialog(false);
    setDateWeekendDialog(false);
    setToast(false);
    setErrorDialog(false);
    setValidationDialogMessage(false);
  };

  const handleInput = (name, item) => {
    setVars((prevState) => {
      let obj = { ...prevState };
      item !== null ? (obj[name] = item) : (obj[name] = '');
      return obj;
    });
  };

  const addNamedContact = (name, email, origin) => {
    let flag = false;

    setNamedContacts((prevState) => {
      for (let i = 0; i < prevState.length; i++) {
        if (prevState[i].origin === origin) {
          prevState[i].name = name;
          prevState[i].email = email;
          flag = true;
          break;
        }
      }

      if (!flag) {
        prevState.push({
          name: name,
          email: email,
          origin: origin,
        });
      }

      return prevState;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    setLoading(true);
    sleep(500);
    const variables = { ...vars, sendingProtocolNumber: vars.sendingProtocolNumber.protocolNumber };
    isUSDAAnimal(variables.species).isUSDA ? delete variables.containers : delete variables.animals;
    if (buildingNameToCode(variables.sendingBuilding)) {
      variables.sendingBuilding = buildingNameToCode(variables.sendingBuilding);
    }
    addExport({ variables });
  };

  return (
    <StandardWrapper>
      <Typography variant="h1">Exports</Typography>
      <form
        id="export-form"
        onSubmit={handleSubmit}
        onKeyPress={(event) => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <Grid container alignContent="center" justifyContent="center" spacing={2} columnSpacing={3}>
          <Grid item xs={12}>
            <Grid item xs={12} textAlign="left">
              <FormDivider text="Instructions" />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack textAlign="left" spacing={3}>
              <Typography variant="p" fontWeight="bold">
                &bull; Contact the{' '}
                <a
                  style={{ fontWeight: 'bold', color: 'black' }}
                  target="_blank"
                  href="https://www.rsp.wisc.edu/contracts/mta.cfm"
                  rel="noreferrer"
                >
                  Office of Research and Sponsored Programs
                </a>{' '}
                to determine if a UW Material Transfer Agreement (MTA) is needed. This step is required for all research
                materials leaving campus.
              </Typography>
              <Typography variant="p" fontWeight="bold">
                &bull;Prior to completing this form, have the name and email addresses of the receiving contacts ready.
                Including the transfers coordinator and facility veterinarian at the Receiving institution.
              </Typography>
              <Typography variant="p" fontWeight="bold">
                &bull; Please review{' '}
                <a
                  style={{ fontWeight: 'bold', color: 'black' }}
                  href="https://www.rarc.wisc.edu/tools_and_guides/animal_transfer.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  RARC Animal Transfer Page
                </a>{' '}
                for more information on the Export Process.
              </Typography>
              <Typography variant="p" fontWeight="bold">
                &bull; Lab staff or facility designee, are responsible for packing animals and meeting the courier
                driver. Additional shipping details will be provided by the RARC Transfers Coordinator.
              </Typography>{' '}
              <br />
              <Typography variant="p" fontWeight="bold">
                &bull; All animals for import are assumed to be BSL1. If animals will be inoculated with any Biohazard
                and/or Chemical Hazard <em>prior to shipment</em> you must include details below in "Special
                Requirements".
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} textAlign="left">
            <FormDivider text="Submitter Information" />
          </Grid>
          <SubmitterGroup
            userInfo={userInfo}
            sendInput={handleInput}
            addContact={addNamedContact}
            key={formReset + 'Submitter Group'}
            val={vars}
          />
          <Grid item xs={12} textAlign="left">
            <FormDivider text="Sending Information (UW)" />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              sx={{ width: '100%' }}
              className="checkbox"
              control={
                <Checkbox
                  checked={breedingCore}
                  onChange={() => {
                    if (!breedingCore) {
                      setVars((vars) => ({
                        ...vars,
                        sendingBuilding: 'SMI Bardeen',
                        sendingContactName: 'Fox Coenen',
                        sendingContactEmail: 'brms-rs-mouse@g-groups.wisc.edu',
                        sendingContactPhone: '608-234-2511',
                        species: 'Mouse',
                      }));
                    } else {
                      setVars((vars) => ({
                        ...vars,
                        sendingBuilding: '',
                        sendingRoom: '',
                        sendingContactName: '',
                        sendingContactEmail: '',
                        sendingContactPhone: '',

                        species: '',
                      }));
                    }
                    setBreedingCore((prev) => !prev);
                  }}
                />
              }
              label="Check here if the animals are being exported from the Mouse Breeding Core"
            />
          </Grid>
          {breedingCore && (
            <Grid item xs={12} textAlign="left">
              <Typography variant="p">
                * Exports from the Breeding Core will cost $240 to export up to 50 animals.
              </Typography>
            </Grid>
          )}

          {/* Protocol Search */}
          <Grid item xs={12} sm={6}>
            <Autocomplete
              filterOptions={createFilterOptions({ limit: 5 })}
              options={protocols ? protocols : ['loading']}
              // getOptionLabel={(option) => option.label}
              value={vars.sendingProtocolNumber || ''}
              onChange={(e, value) => {
                handleInput('sendingProtocolNumber', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  placeholder="Enter protocol"
                  label="Protocol"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          {/* Building Search */}
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={ALL_BUILDINGS.map((x) => x.name)}
              value={vars.sendingBuilding}
              onChange={(e, value) => handleInput('sendingBuilding', value)}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    required
                    placeholder="Enter building"
                    fullWidth
                    label="Building"
                    variant="outlined"
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              required
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Animal Housing Room"
              placeholder="Enter room #"
              value={vars.sendingRoom}
              onChange={(e) => handleInput('sendingRoom', e.target.value)}
              helperText={breedingCore ? '* Get your animal housing room from your colony manager or Mosaic' : null}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Sending Lab Contact Name"
              placeholder={vars.sendingContactName ? '' : 'Enter name'}
              value={vars.sendingContactName}
              onChange={(e) => {
                if (vars.sendingContactEmail) addNamedContact(e.target.value, vars.sendingContactEmail, 'sending');
                handleInput('sendingContactName', e.target.value);
              }}
              InputLabelProps={{ shrink: !!vars.sendingContactName }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              required
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Sending Lab Contact Email"
              placeholder="Enter email"
              type="email"
              value={vars.sendingContactEmail}
              onChange={(e) => {
                if (vars.sendingContactName) addNamedContact(vars.sendingContactName, e.target.value, 'sending');
                handleInput('sendingContactEmail', e.target.value);
              }}
              InputLabelProps={{ shrink: !!vars.sendingContactEmail }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneInput
              isRequired={true}
              name="sendingContactPhone"
              label="Sending Lab Contact Phone"
              key={sendingReset}
              val={vars.sendingContactPhone}
              sendInput={handleInput}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              required
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Sending Billing String"
              placeholder="Enter billing string"
              value={vars.sendingBillingString}
              onChange={(e) => handleInput('sendingBillingString', e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              required
              label="MTA Status"
              key={formReset}
              val={vars.mtaStatus}
              onChange={(e) => handleInput('mtaStatus', e.target.value)}
              menu={[
                { value: 'submitted', text: 'Submitted' },
                { value: 'approved', text: 'Approved/Signed' },
                { value: 'notRequired', text: 'Not Required by Recipent' },
              ]}
            />
          </Grid>

          <Grid item xs={12} textAlign="left">
            <FormDivider text="Receiving Information (Other Institution)" />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              required
              key={receivingReset}
              variant="outlined"
              fullWidth
              label="Institution"
              placeholder="Enter name of institution"
              value={vars.receivingInstitution}
              onChange={(e) => handleInput('receivingInstitution', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              key={receivingReset}
              variant="outlined"
              fullWidth
              label="PI Name"
              placeholder="Enter name"
              value={vars.receivingPI}
              onChange={(e) => handleInput('receivingPI', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              required
              key={receivingReset}
              variant="outlined"
              fullWidth
              label="Lab Contact Name"
              placeholder="Enter name"
              value={vars.receivingContactName}
              onChange={(e) => {
                if (vars.receivingContactEmail)
                  addNamedContact(e.target.value, vars.receivingContactEmail, 'receiving');
                handleInput('receivingContactName', e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              key={receivingReset}
              variant="outlined"
              fullWidth
              label="Lab Contact Email"
              placeholder="Enter email"
              type="email"
              value={vars.receivingContactEmail}
              onChange={(e) => {
                if (vars.receivingContactName) addNamedContact(vars.receivingContactName, e.target.value, 'receiving');
                handleInput('receivingContactEmail', e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Transfer Coordinator Name"
              required
              placeholder="Enter name"
              value={vars.receivingTransferCoordinatorName}
              onChange={(e) => handleInput('receivingTransferCoordinatorName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              key={sendingReset}
              fullWidth
              label="Transfer Coordinator Email"
              required
              placeholder="Enter email"
              type="email"
              value={vars.receivingTransferCoordinatorEmail}
              onChange={(e) => handleInput('receivingTransferCoordinatorEmail', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              key={sendingReset}
              variant="outlined"
              fullWidth
              label="Facility Vet Name"
              placeholder="Enter name"
              required
              value={vars.receivingVetName}
              onChange={(e) => handleInput('receivingVetName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              key={sendingReset}
              fullWidth
              label="Facility Vet Email"
              placeholder="Enter email"
              type="email"
              value={vars.receivingVetEmail}
              onChange={(e) => handleInput('receivingVetEmail', e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12} textAlign="left">
            <FormDivider text="Animal Information" />
          </Grid>

          <Grid container item xs={12} sm={6}>
            <Grid item xs={12}>
              <Autocomplete
                options={SMPHAnimals}
                value={vars.species}
                onChange={(e, value) => handleInput('species', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    placeholder="Enter species"
                    label="Species"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} textAlign="left">
              <FormControlLabel
                className="checkbox"
                control={
                  <Checkbox
                    key={formReset}
                    checked={vars.immunodeficient}
                    onChange={(e) => handleInput('immunodeficient', e.target.checked)}
                  ></Checkbox>
                }
                label="Immunodeficient"
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} textAlign="left">
            <FormControl>
              <FormLabel>Have these animals been used in a previous study?</FormLabel>
              <RadioGroup
                onChange={(e) => handleInput('naive', e.target.value === 'Yes' ? false : true)}
                defaultValue={'Yes'}
                name="radio-buttons-group"
              >
                <FormControlLabel value={'Yes'} control={<Radio />} label="Yes" />
                <FormControlLabel value={'No'} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <SelectInput
              menu={[
                { value: 1, text: '1' },
                { value: 2, text: '2' },
                { value: 3, text: '3' },
              ]}
              label="Biosafety Level"
              required
              key={formReset}
              val={vars.bsl}
              onChange={(e) => handleInput('bsl', parseInt(e.target.value))}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {vars.bsl >= 2 && (
              <TextField
                required
                variant="outlined"
                label="Biohazardous Agents"
                placeholder="Describe the hazardous agent(s)"
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                value={vars.biohazardAgent}
                onChange={(e) => handleInput('biohazardAgent', e.target.value)}
              ></TextField>
            )}
          </Grid>

          <TableSwitch
            key={formReset + USDA + 'table-switch'}
            sendInput={handleInput}
            species={vars.species ? vars.species : ''}
            val={vars.animals}
            allRequired={true}
            USDAStatus={USDA}
            setUSDAStatus={setUSDA}
          >
            <Grid item xs={12} textAlign="left">
              <FormDivider text="Cages / Tanks" />
            </Grid>

            <ContainerTable key={formReset + 'container-table'} val={vars.containers} sendInput={handleInput} />
          </TableSwitch>
          <Grid item xs={12} textAlign="left">
            <FormDivider text="Other Contacts" />
          </Grid>
          <Grid item xs={12}>
            <EmailList
              key={formReset}
              sendInput={handleInput}
              existingContacts={namedContacts}
              instructions="A copy of this document will be sent to the contacts provided above .You may add email addresses for additional contacts from sending or receiving groups."
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              key={formReset}
              variant="outlined"
              label="Special Requirements / Other Information"
              placeholder="Enter additional comments/instructions"
              fullWidth
              multiline
              minRows={3}
              maxRows={6}
              value={vars.addlRequirements}
              onChange={(e) => handleInput('addlRequirements', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} textAlign="right">
            <Button style={{ width: '10em' }} variant="contained" color="primary" type="submit" disabled={loading}>
              Submit
              {loading && (
                <CircularProgress disableShrink style={{ position: 'absolute', zIndex: 2, color: 'white' }} size={24} />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        open={toast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleToastClose} variant="filled" severity="success">
          <AlertTitle>Success</AlertTitle>
          Export has been submitted!
        </Alert>
      </Snackbar>
      <Dialog open={!!dateCheckDialog} onClose={handleToastClose}>
        <DialogTitle>{'Information'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A notice of 8 business days is preferred in advance of an export. Contact the veterinarian if this transfer
            is urgent.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!dateWeekendDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Exports cannot be requested to arrive on weekends.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>The export failed to submit.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!validationDialogMessage} onClose={handleToastClose}>
        <DialogTitle>{'Form not Completed'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{validationDialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {infoToasts.map((toast) => (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={toast.active}
          onClose={() => disableInfoToast(toast._id)}
          key={toast._id}
        >
          <Alert severity="info">{toast.message}</Alert>
        </Snackbar>
      ))}
    </StandardWrapper>
  );
};

export default ExportForm;
