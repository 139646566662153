import React, { useState } from 'react';
import {
  Table,
  Paper,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TextField,
  TableFooter,
  IconButton,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
} from '@mui/material';
import { sleep } from '../utils';
import HelpIcon from '@mui/icons-material/Help';

const setFocus = () => {
  document.getElementsByTagName('TABLE')[0].setAttribute('id', 'data-table');

  // Select table > body > last table row > first cell in last table row > textfield inside the cell
  let nextRow =
    document.getElementById('data-table').lastElementChild.firstElementChild.firstElementChild.firstElementChild
      .firstElementChild;

  nextRow.focus();
};

const ContainerTable = ({ sendInput, val }) => {
  const [data, setData] = useState([]);

  const [newRow, setNewRow] = useState({
    id: '1',
  });

  const [clearKey, setClearKey] = useState(false);

  const [errorDialog, setErrorDialog] = useState(false);

  const appendNewRow = (key, value) => {
    let temp = newRow;
    temp[key] = value;
    setNewRow(temp);
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorDialog(false);
  };

  return (
    <>
      <TableContainer className="datatable" component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Cage / Tank Number</TableCell>
              <TableCell align="center">Number of Males</TableCell>
              <TableCell align="center">Number of Females</TableCell>
              <TableCell align="center">DOB</TableCell>
              <TableCell align="center">Coat Color</TableCell>
              <TableCell align="center">Strain</TableCell>
              <TableCell align="center">ID (Ear tag, punch, etc)</TableCell>
              <TableCell>
                <Tooltip title="Each row represents a cage that is being shipped. Press the plus icon to add more cages/tanks.">
                  <IconButton aria-label="info">
                    <HelpIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow className="datatable-filled-row" key={row.id}>
                <TableCell align="center">{row.containerNumber}</TableCell>
                <TableCell align="center">{row.numMales}</TableCell>
                <TableCell align="center">{row.numFemales}</TableCell>
                <TableCell align="center">{row.dob}</TableCell>
                <TableCell align="center">{row.coatColor}</TableCell>
                <TableCell align="center">{row.strain}</TableCell>
                <TableCell align="center">{row.identification}</TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    textAlign: 'end',
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    onClick={({ id = row.id }) => {
                      let temp = data.filter((entry) => entry.id !== id);
                      setData(temp);
                      sendInput('containers', JSON.stringify(temp));
                    }}
                  >
                    <Icon color="primary">delete</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell align="center">
                <TextField key={clearKey} onChange={(e) => appendNewRow('containerNumber', e.target.value)} />
              </TableCell>
              <TableCell align="center">
                <TextField key={clearKey} onChange={(e) => appendNewRow('numMales', e.target.value)}></TextField>
              </TableCell>
              <TableCell align="center">
                <TextField key={clearKey} onChange={(e) => appendNewRow('numFemales', e.target.value)}></TextField>
              </TableCell>
              <TableCell align="center">
                <TextField key={clearKey} onChange={(e) => appendNewRow('dob', e.target.value)}></TextField>
              </TableCell>
              <TableCell align="center">
                <TextField key={clearKey} onChange={(e) => appendNewRow('coatColor', e.target.value)}></TextField>
              </TableCell>
              <TableCell align="center">
                <TextField key={clearKey} onChange={(e) => appendNewRow('strain', e.target.value)} />
              </TableCell>
              <TableCell align="center">
                <TextField key={clearKey} onChange={(e) => appendNewRow('identification', e.target.value)} />
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'end',
                }}
              >
                <IconButton
                  aria-label="add"
                  onClick={() => {
                    if (Object.keys(newRow).length !== 8) setErrorDialog(true);
                    else {
                      let newData;
                      data.length === 0 ? (newData = [newRow]) : (newData = [...data, newRow]);
                      setData(newData);
                      setNewRow({ id: `${parseInt(newRow.id) + 1}` });
                      setClearKey(!clearKey);
                      sendInput('containers', JSON.stringify(newData));
                      sleep(200).then(setFocus);
                    }
                  }}
                >
                  <Icon color="secondary">add_circle</Icon>
                </IconButton>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Dialog open={errorDialog} onClose={handleToastClose}>
        <DialogTitle>{'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText>All fields are required.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToastClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <em>
        You must press the blue plus icon to add the container. Filling out fields without pressing the plus icon will
        not submit that container in the transfer request.
      </em>
    </>
  );
};

export default ContainerTable;
